import request from '@/utils/requestV2'
const qs = require('qs')

// 新增某个甲方
export function addCustomer (data) {
  return request({
    url: '/ooh-scp/v1/myfirstparty/addcustomer',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 销售甲方：验重
export function checkCustomerRepeat (data) {
  return request({
    url: '/ooh-scp/v1/myfirstparty/checkcustomerrepeat',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除某个甲方
export function deleteCustomer (data) {
  return request({
    url: '/ooh-scp/v1/myfirstparty/delete',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 条件查询甲方，分页显示
export function getCustomerPage (data) {
  return request({
    url: '/ooh-scp/v1/myfirstparty/getcustomerpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取某个甲方
export function getCustomer (data) {
  return request({
    url: '/ooh-scp/v1/myfirstparty/getcustomer',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 更新某个甲方
export function updateCustomer (data) {
  return request({
    url: '/ooh-scp/v1/myfirstparty/updatecustomer',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取客户的甲方
export function getAdvertiserCustomerPage (data) {
  return request({
    url: '/ooh-scp/v1/myfirstparty/getadvertisercustomerpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
