
<template>
  <div>
    <div style="padding: 10px" class="transaction-content">
        <div class="workplatform-title"> {{customer.name}} </div>
        <Row>
          <i-col span="8"><span class="title">类型 </span>
            <span>{{customer.typeName }}</span>
          </i-col>
          <i-col span="8"><span class="title">所属媒介主 </span>
            <span>{{customer.publisherName  }}</span>
          </i-col>
          <i-col span="8"><span class="title">所属代理商 </span>
            <span>{{customer.reportCompanyName  }}</span>
          </i-col>
          <i-col span="8"><span class="title">地址 </span>
            <span>{{customer.provinceName  }}-{{customer.cityName   }}-{{customer.areaName   }}-{{customer.address   }}</span>
          </i-col>
          <i-col span="8"><span class="title">联系方式 </span>
            <span>{{customer.tel  }}</span>
          </i-col>
          <i-col span="8"><span class="title">统一社会信用代码 </span>
            <span>{{customer.orgCode  }}</span>
          </i-col>

        </Row>
    </div>

    <i-form class="p-t-10" ref="firstPartyForm" :model="firstParty" label-position="top" :rules="rules">
      <Row :gutter="10">
        <i-col span="12">
          <FormItem label="甲方名称" prop="name">
            <i-input v-model="firstParty.name" placeholder="请输入名称" disabled></i-input>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem  prop="code">
           <span slot="label">纳税人识别号/社会统一信息代码 <small v-show="repeat" class="text-red">(已有重复的合同甲方)</small>  </span>
           <i-input v-model="firstParty.code" placeholder="格式：91510100MA62L2A037、MA62L2A03、510108600454493" @on-blur="validateCustomer"></i-input>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="10">
        <i-col span="12">
          <FormItem label="联系人" prop="contact">
            <i-input v-model="firstParty.contact" placeholder="请输入联系人"></i-input>
          </FormItem>
        </i-col>
        <i-col span="12">
           <FormItem label="联系电话" prop="tel">
            <i-input v-model="firstParty.tel" placeholder="请输入联系方式"></i-input>
          </FormItem>

        </i-col>
        <i-col span="12">
          <FormItem label="电子邮箱" prop="email">
            <i-input v-model="firstParty.email" placeholder="请输入电子邮箱"></i-input>
          </FormItem>
        </i-col>
        <i-col span="12">
           <FormItem label="传真" prop="fax">
            <i-input v-model="firstParty.fax" placeholder="请输入传真"></i-input>
          </FormItem>

        </i-col>
      </Row>
       <Row :gutter="10">
         <i-col span="12">
          <FormItem label="发票类型" prop="invoiceType">
            <RadioGroup v-model="firstParty.invoiceType">
              <Radio v-for="invoice in invoiceTypes" :key="invoice.value" :label="invoice.value">{{invoice.name}}</Radio>
            </RadioGroup>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="地址" prop="address">
            <i-input v-model="firstParty.address" placeholder="请输入地址"></i-input>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="10">
         <i-col span="12">
          <FormItem label="开户行" prop="bank" :required="required" :show-message="false">
            <i-input v-model="firstParty.bank" placeholder="请输入 开户行"></i-input>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="银行账号" prop="bankAccount" :required="required" :show-message="false">
            <i-input v-model="firstParty.bankAccount"  placeholder="请输入 银行账号"></i-input>
          </FormItem>
        </i-col>
      </Row>

      <FormItem>
        <i-button v-if="allowEdit" type="success" @click="handleSubmit" >保存</i-button>
      </FormItem>
    </i-form>
  </div>
</template>

<script>
import { getCustomer, updateCustomer, checkCustomerRepeat } from '@/api/scp/myfirstparty'
import { getAdvertiser } from '@/api/crm/advertiser'

export default {
  data () {
    return {
      firstParty: {
        name: '',
        address: '',
        tel: '',
        contact: '',
        email: '',
        fax: '',
        invoiceType: 0,
        bank: '',
        bankAccount: '',
        code: ''
      },
      rules: {
        name: [{ required: true, message: ' ' }],
        tel: [{ required: true, message: ' ' }],
        contact: [{ required: true, message: ' ' }],
        code: [{ required: true, pattern: /^([a-zA-Z0-9]{2}[0-9]{6}[a-zA-Z0-9]{10}|[a-zA-Z0-9]{9}|[a-zA-Z0-9]{15})$/, message: ' ' }]
        // bank: [{ required: true, message: '开户行 不能为空' }],
        // bankAccount: [{ required: true, message: '银行账号 不能为空' }]
      },
      customer: {},
      invoiceTypes: [
        { value: 0, name: '专票' },
        { value: 1, name: '普票' }
      ],
      required: false,
      repeat: false,
      oldBank: '',
      oldBankAccount: ''
    }
  },
  methods: {
    handleSubmit () {
      this.$refs.firstPartyForm.validate(valid => {
        if (valid) {
          const firstParty = this.firstParty
          firstParty.customerId = this.customerId
          updateCustomer(firstParty).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '保存成功' })
              this.$emit('on-updated')
            }
          })
        } else {
          this.$Notice.error({ desc: '请查证后重试' })
        }
      })
    },
    validateCustomer () {
      if (this.firstParty.code) {
        checkCustomerRepeat({ code: this.firstParty.code }).then(res => {
          this.repeat = res.some(x => x.id !== this.detailId)
        })
      }
    }

  },
  created () {
    getCustomer({ id: this.detailId }).then(firstParty => {
      this.firstParty = firstParty
      this.required = this.firstParty.invoiceType === 0
      this.validateCustomer()
      getAdvertiser({ advertiserId: firstParty.advertiserId }).then(advertiser => {
        this.customer = advertiser
      })
    })
  },
  computed: {
    detailId () {
      return this.$store.state.advertiser.detailId
    },
    allowEdit () {
      return this.isAuth('FirstParty_Edit')
    }
  },
  watch: {
    'firstParty.bank' (newVal, oldVal) {
      this.oldBank = oldVal
    },
    'firstParty.bankAccount' (newVal, oldVal) {
      this.oldBankAccount = oldVal
    },
    'firstParty.invoiceType': function () {
      this.required = this.firstParty.invoiceType === 0
      if (this.required) {
        this.firstParty.bank = this.oldBank
        this.firstParty.bankAccount = this.oldBankAccount
      }
    }
  }
}
</script>
